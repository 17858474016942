import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { APIPortalTicketExecuteServiceGETStep, APIPortalTicketExecuteServiceGETTicket, APIPortalTicketExecuteServicePATCHResponseSuccess } from 'src/app/api-services/portal/ticket-execute.interface';
import { Steps } from './components/ticket-stages/ticket-stages.component';
import { APIPortalTicketsServiceGETItemStatus } from 'src/app/api-services/portal/tickets.interface';
import { AuthPermissionsService } from 'src/app/auth/auth-permissions.service';
import { UserPermissions } from 'src/app/auth/auth.interface';

@Injectable({
	providedIn: 'root'
})
export class ServiceOrderPlanningSideNavAssetTicketRouteService {

	readonly loadOtherTicket = new Subject<{ticketPrefix: number; ticketCode: number}>();
	readonly reloadCurrentTicket = new Subject<boolean>();
	readonly ticketStartUpdate = new Subject<APIPortalTicketExecuteServiceGETTicket>();
	readonly ticketFinishUpdate = new Subject<APIPortalTicketExecuteServiceGETTicket>();
	readonly ticketOpenModalCheckoutMoveKanbanStage = new Subject<Steps>();

	constructor(
		private authPermissionsService: AuthPermissionsService
	){}

	patchTicketObserveConstructor(
		currentTicket: APIPortalTicketExecuteServiceGETTicket,
		observe: PatchTicketObserveSCNConstructor
	): PatchTicketObserveConstructor {
		return {
			next: result => {
				if(!result.body.scnChanged){
					this.ticketFinishUpdate.next(currentTicket);
					if(observe.nextSCNNotChanged){
						observe.nextSCNNotChanged(result);
					}
				}else if(observe.nextSCNChanged){
					observe.nextSCNChanged(result);
				}
			},
			error: resultError => {
				if(!resultError.error.scnChanged){
					this.ticketFinishUpdate.next(currentTicket);
					if(observe.errorSCNNotChanged){
						observe.errorSCNNotChanged(resultError);
					}
				}else if(observe.errorSCNChanged){
					observe.errorSCNChanged(resultError);
				}
			}
		};
	}

	isUserInCurrentStepGroup(
		ticket: APIPortalTicketExecuteServiceGETTicket,
		step: APIPortalTicketExecuteServiceGETStep,
		isTemp = true
	){

		let userFocus = false;

		if(isTemp && ticket?.is_tmp === 1){
			return false;
		}

		if(step){
			userFocus = step.user_focus === 1;
		}else if(ticket){
			userFocus = ticket.user_focus === 1;
		}

		return this.authPermissionsService.has(UserPermissions.routeServiceOrderPlanningAssetTicketEdit)
			||
			userFocus
		;
	}

	ticketAbleToBeCancel(
		ticket: APIPortalTicketExecuteServiceGETTicket
	){

		let canBeCancel = false;

		canBeCancel = ticket.user_focus === 1

		return this.authPermissionsService.has(UserPermissions.routeServiceOrderPlanningAssetTicketEdit)
			||
			canBeCancel
		;
	}

	isTicketAbleToDone(
		ticket: APIPortalTicketExecuteServiceGETTicket,
		step: APIPortalTicketExecuteServiceGETStep
	) {
		return this.isUserInCurrentStepGroup(ticket, step)
			&&
		ticket.able_to_done === 1;
	}

	canUserEditTicket(ticket: APIPortalTicketExecuteServiceGETTicket){
		return (
			ticket.ticket_status === APIPortalTicketsServiceGETItemStatus.PENDING
			||
			ticket.ticket_status === APIPortalTicketsServiceGETItemStatus.PROCESS
		)
		&&
		ticket.kanban_open_continue === 0
		&&
		this.authPermissionsService.has(UserPermissions.routeServiceOrderPlanningAssetTicketEdit);
	}

};

interface PatchTicketObserveConstructor {
	next: (result: HttpResponse<APIPortalTicketExecuteServicePATCHResponseSuccess>) => void;
	error: (error: HttpErrorResponse) => void;
}

interface PatchTicketObserveSCNConstructor {
	nextSCNChanged?: (result: HttpResponse<APIPortalTicketExecuteServicePATCHResponseSuccess>)
	=> void;
	nextSCNNotChanged?: (result: HttpResponse<APIPortalTicketExecuteServicePATCHResponseSuccess>)
	=> void;
	errorSCNChanged?: (error: HttpErrorResponse)
	=> void;
	errorSCNNotChanged?: (error: HttpErrorResponse)
	=> void;
}
